/* eslint-disable */
import router from "./router";
import NProgress from "nprogress"; //  progress bar
import "nprogress/nprogress.css"; //  progress bar style
import { userInfoCookie, menuListCookie } from "@/utils/auth";
import store from "@/store";
import { Message } from "element-ui";
var moment = require("moment");

// 取出某一子应用第一个显示页面
function getFirstPath(child) {
  // 递归出第一个path
  if (!child.noDisplay && !child.hidden) {
    let path = child.path;
    if (child.children && child.children.length) {
      path = getFirstPath(child.children[0]);
    }
    return path;
  }
}

NProgress.configure({
  showSpinner: false
}); //  NProgress Configuration

const whiteList = [
  "/portal-login",
  "/reg",
  "/forget-pwd",
  "/auth-redirect",
  "/portal-error401",
  "/404",
  "/print/",
  "/inbound/purchase/print-barcode",
  "/outbound/operation/order-pick-bill-print",
  "/outbound/require/allocation-pick-bill-print",
  "/bigData/3d-garden",
  "/bigData/storage-performance",
  "/bigData/storage-ai",
  "/bigData/storage-progress",
  "/bigData/tms-playback",
  "/bigData/tms-runtime",
  "/ureport/print",
  "/erp/sale/pi-print" // pi打印
]; //  no redirect whitelist

/**
 * 查找菜单是否有权限
 * @param routes asyncRouterMap
 * @param path
 */
function findRouter(routes, path, parentPath) {
  const res = [];
  let isExist = false;

  routes.forEach(route => {
    var currentPath = route.path;
    if (currentPath.indexOf("/") !== 0) {
      currentPath = parentPath + "/" + currentPath;
    }
    if (currentPath.toLowerCase() === path.toLowerCase()) {
      isExist = true;
      return true;
    } else {
      if (route.children) {
        if (findRouter(route.children, path, currentPath)) {
          isExist = true;
        }
      }
    }
  });

  return isExist;
}

/**
 * 校验是否存在白名单中
 * @param path
 */
function checkWhite(path) {
  var isOK = false;
  whiteList.forEach(item => {
    if (path.indexOf(item) >= 0) isOK = true;
  });
  return isOK;
}

router.beforeEach((to, from, next) => {
  NProgress.start(); //  start progress bar
  if (checkWhite(to.path)) {
    //  在免登录白名单，直接进入
    let userInfo = store.getters.userInfo;
    if (!userInfo || !userInfo.accessToken) {
      store.dispatch("GetUserInfo").then(res => {
        next();
        NProgress.done();
        return;
      });
    } else {
      next();
      NProgress.done();
    }
    return;
  }
  var userInfo = userInfoCookie.getUserInfo();
  if (userInfo && userInfo.accessToken) {
    //  determine if there has token
    /* has token */
    if (to.path === "/portal-login") {
      next();
      NProgress.done(); //  if current page is dashboard will not trigger	afterEach hook, so manually handle it
    } else {
      let userInfo = store.getters.userInfo;
      // var addRouters = store.getters.addRouters;
      // if (!userInfo || !userInfo.accessToken || !addRouters.length) {
      if (!userInfo || !userInfo.accessToken || !store.getters.showSubApp) {
        store.dispatch("GetUserInfo").then(res => {
          if (!res.menuList) {
            next(`/portal-login?redirect=${to.path}`); //  否则全部重定向到登录页
            NProgress.done(); //  if current page is login will not trigger afterEach hook, so manually handle it
            return;
          }
          // 生成菜单
          // store.dispatch("GenerateRoutes", res).then(() => {
          //   //  根据roles权限生成可访问的路由表
          //   var routers = store.getters.addRouters;
          //   if (!routers.length) {
          //     Message.error("没有设置任何权限！");
          //     userInfoCookie.removeUserInfo(); // 清空登录信息，重新登录
          //     next(`/portal-login?redirect=${to.path}`); //  全部重定向到登录页
          //   } else {
          //     // Message.success("登录成功");
          //     router.addRoutes(routers); //  动态添加可访问路由表
          //     next({ ...to, replace: true });
          //   }
          // });

          // 拉取user_info
          const { menu } = res;
          // 注入子应用
          store.dispatch("generateSubApp", menu).then(() => {
            // 左侧动态路由
            const subAppPrefix = sessionStorage.getItem("subAppPrefix", true);
            let appMenus, index, redirect;
            const subApp = store.getters.subApp;
            if (subAppPrefix) {
              index = subApp.findIndex(item => item.appPrefix === subAppPrefix);
              appMenus = subApp[index].appMenus;
            } else {
              // 排除 登录页跳过来的 携带参数情况
              // 登录页过来 携带 redirect参数
              if (from.path === "/portal-login" && from.query.redirect) {
                redirect = "/" + from.query.redirect.split("/")[1];
                index = subApp.findIndex(item => item.appPrefix === redirect);
                if (index === -1) {
                  // 如果 redirect 不属于子应用 路由体系的话
                  redirect = subApp[0].appPrefix;
                  appMenus = subApp[0].appMenus;
                } else {
                  appMenus = subApp[index].appMenus;
                }
              } else {
                // 登录页过来 不携带 redirect参数 默认取第一项
                index = -1;
                redirect = subApp[0].appPrefix;
                appMenus = subApp[0].appMenus;
              }
              sessionStorage.setItem("subAppPrefix", redirect, true);
            }
            store.dispatch("generateRoutes", appMenus).then(() => {
              // 根据menu id生成可访问的路由表
              router.addRoutes(store.getters.addRouters); // 动态添加可访问路由表
              store.dispatch("setShowTrue"); // 解决 登录时 动态路由未加载情况
              // 改变跳转地址 防止首次登录跳转 / 和 /portal-404 页面
              // if (index === -1) {
              //   let path,
              //     appMenus = subApp[0].appMenus;
              //   for (let i = 0; i < appMenus.length; i++) {
              //     path = getFirstPath(appMenus[i]);
              //     if (path) break;
              //   }
              //   next({ path, replace: true }); // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
              // } else {
              //   next({ ...to, replace: true }); // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
              // }
              next({ ...to, replace: true }); // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
            });
          });
        });
      } else {
        var routes = store.getters.permission_routers;
        const existRoute = findRouter(routes, to.path, "");

        if (existRoute) {
          next();
        } else {
          next({
            path: "/portal-error401",
            replace: true,
            query: {
              noGoBack: true
            }
          });
        }
      }
      NProgress.done(); //  finish progress bar
    }
  } else {
    next(`/portal-login?redirect=${to.path}`); //  否则全部重定向到登录页
    NProgress.done(); //  if current page is login will not trigger afterEach hook, so manually handle it
  }
});

router.afterEach(() => {
  NProgress.done(); //  finish progress bar
});
